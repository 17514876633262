import React, { FC } from "react";
import { SEO } from "../components/SEO";

const NotFoundPage: FC = () => (
  <div>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>指定されたページは存在しません。</p>
  </div>
);

export default NotFoundPage;
